<template>
    <div>
        <div id="BG_pie" ref="pie"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        dataList: Object,
    },
    watch: {
        dataList() {
            // setTimeout(() => {
                this.getDataPie()
            // }, 500)

        }
    },
    mounted() {
        this.getDataPie()
    },

    methods: {
        getDataPie() {
            if (this.dataList[2][0] == '0.00') {
                let arr = JSON.parse(JSON.stringify(this.dataList))
                delete arr[0][4]
                delete arr[1][4]
                let data = arr[1].concat(arr[0])
                // console.log(data);
                var opion = {
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        {
                            type: 'pie',
                            // color: [ "#529EE2", "#87CA6B", "#FAD33F", "#F2637B","#7E98FE", "#F88E36", "#3EBB9F", "#91C6FB",],
                            radius: ['0%', '60%'],
                            center: ['50%', '50%'],
                            startAngle: 90,
                            label: {
                                show: true,
                                formatter(param) {
                                    return param.name + ':\n' + param.percent * 2 + '%';
                                }
                            },
                            data: data
                        }
                    ]
                }
                this.$echarts.init(this.$refs.pie).setOption(opion)
            } else {
                var opions = {
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        {
                            name: '资产',
                            type: 'pie',
                            radius: ['0%', '60%'],
                            center: ['48%', '50%'],
                            startAngle: -90,
                            label: {
                                show: true,
                                formatter(param) {
                                    return param.name + ':\n' + param.percent * 2 + '%';
                                }
                            },
                            data: this.dataList[0]
                        },
                        {
                            name: '负债和所有者权益',
                            type: 'pie',
                            radius: ['0%', '60%'],
                            center: ['50%', '50%'],
                            startAngle: 90,
                            label: {
                                show: true,
                                formatter(param) {
                                    return param.name + ':\n' + param.percent * 2 + '%';
                                }
                            },
                            data: this.dataList[1]
                        }
                    ]
                }
                this.$echarts.init(this.$refs.pie).setOption(opions)
            }

        },
    }
}
</script>

<style>
#BG_pie {
    width: 100%;
    height: 250px;
}
</style>