<template>
    <div>
        <div id="bar" class="bar" ref="bar"></div>
    </div>
</template>

<script>

export default {
    data() {
        return {

        }
    },
    props: {
        dataList: Object,
        yearList: String,
    },
    watch: {
        data() {

        },
        dataList() {

            this.getDataPie()
        }
    },
    mounted() {
        // this.getDataPie()
    },

    methods: {
        getDataPie() {
            // console.log(this.dataList);
            var option = {
                color: 'rgb(248, 142, 54)',
                title: {
                    text: this.yearList,
                    bottom: '0',
                    left: 'center'
                },
                xAxis: {
                    type: 'value',
                    show: false,
                },
                yAxis: {
                    name: '单位：万元',
                    data: ["营业利润", "资产减值损失", "投资收益", "研发费用", "财务费用", "管理费用", "销售费用", '营业成本', '营业收入'],
                    type: 'category'
                },
                grid: {
                    show: false,
                    left: 80,
                    // top: 40,
                    // bottom: 40,
                    right: 100,
                },
                series: [
                    {
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 12,
                            formatter: function (params) {
                                let num = Number(params.value)
                                return (
                                    num &&
                                    (num.toString().indexOf(".") != -1
                                        ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
                                            return $1 + ",";
                                        })
                                        : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
                                            return $1 + ",";
                                        }))
                                );
                            },
                            color: "#333",
                        },
                        data: this.dataList

                    }
                ]
            };
            this.$echarts.init(this.$refs.bar).setOption(option)
        }
    }
}
</script>

<style>
.bar {
    width: 400px;
    height: 350px;
}
</style>