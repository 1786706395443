<template>
  <div style="positon: relative">
    <div class="test1" v-if="getUserRole('overall_evaluation')">
      <totalCheck ref="totalCheck" />
    </div>
    <div class="test1" v-if="getUserRole('Industry_trend')">
      <totalTendency ref="totalTendency" />
    </div>
    <div
      style="background: #fff"
      class="mt-4 test1"
      v-if="getUserRole('Intelligent_tool')"
    >
      <div class="title">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        智能工具
      </div>
      <div class="title-p row justify-content-between">
        <div>
          <span>资产负债表</span>
          <span style="font-size: 14px"> (前四大科目占比展示)</span>
        </div>
        <div style="display: flex">
          <div class="ml-2">图形模式：</div>
          <div style="width: 140px; color: #000">
            <el-button-group>
              <el-button
                @click="toggleTable(false)"
                size="mini"
                :class="!showTable && 'btnActive'"
                >图形</el-button
              >
              <el-button
                @click="toggleTable(true)"
                size="mini"
                :class="showTable && 'btnActive'"
                >表格</el-button
              >
            </el-button-group>
          </div>
        </div>
      </div>

      <div style="width: 100%">
        <div class="pl-30px pt-3 pb-3 pr-30px">
          <div v-show="!showTable" class="row justify-content-around">
            <div
              style="width: 30%"
              v-for="(data, index) in ArrList"
              :key="index"
            >
              <div
                class="text-center"
                style="font-size: 18px; font-weight: 550; color: #333"
              >
                {{ reportData.Result.FB.rpt_time_str[index] }}
              </div>
              <!-- <Pie_data :dataList="JSON.parse(JSON.stringify(data))"></Pie_data> -->
              <Bar_data :dataList="JSON.parse(JSON.stringify(data))"></Bar_data>
              <!-- <div
                class="text-center"
                style="font-size: 16px; font-weight: 550; color: #333"
              >
                资产 | 负债和所有者权益合计
              </div> -->
              <div
                class="text-center"
                style="font-size: 22px; font-weight: 550; color: #3a85fd"
              >
                <span>{{ thousandBitSeparator(data[2]) }}</span>
                <span style="font-size: 19px; margin-top: -3px"> (万元)</span>
              </div>
            </div>
          </div>
          <div class="mb_jian" v-show="showTable">
            <liaTable
              :dataList="JSON.parse(JSON.stringify(ArrList))"
              :year="reportData.Result.FB.rpt_time_str"
            >
            </liaTable>
          </div>
        </div>
        <div class="title-p row justify-content-between">
          <div>利润表</div>
          <div style="display: flex">
            <div style="width: 90px" class="ml-2">图形模式：</div>
            <div style="width: 140px; color: #000">
              <el-button-group>
                <el-button
                  @click="toggleTable1(false)"
                  size="mini"
                  :class="!showTable1 && 'btnActive'"
                  >图形</el-button
                >
                <el-button
                  @click="toggleTable1(true)"
                  size="mini"
                  :class="showTable1 && 'btnActive'"
                  >表格</el-button
                >
              </el-button-group>
            </div>
          </div>
        </div>
        <!--利润表-->
        <div class="pl-30px bgc_w pt-3 pb-3 pr-30px" style="width: 100%">
          <div v-show="!showTable1" class="legend">
            <div class="end">
              <div style="background: #529ee2" class="leg"></div>
              <span>收入/结余</span>
            </div>
            <div class="end">
              <div style="background: #f88e36" class="leg"></div>
              <span>支出</span>
            </div>
          </div>
          <div
            class="row justify-content-around"
            v-show="!showTable1"
            style="width: 100%"
          >
            <Bar :dataList="dataFunnelOne" :yearList="dataFunnelYearOne"></Bar>
            <Bar :dataList="dataFunnelTwo" :yearList="dataFunnelYearTwo"></Bar>
            <Bar
              v-show="flag"
              :dataList="dataFunnelThree"
              :yearList="dataFunnelYearThree"
            ></Bar>
          </div>
          <div v-show="showTable1" class="mb_jian">
            <profitTable
              v-bind:tableData="reportData.Result.FP.content"
              :years="reportData.Result.FB.year"
              :report_time="report_time"
            ></profitTable>
          </div>
        </div>
        <template>
          <div class="title-p row justify-content-between">
            <div>现金流量表</div>
            <div style="display: flex">
              <div style="width: 90px" class="ml-2">图形模式：</div>
              <div style="width: 140px; color: #000">
                <el-button-group>
                  <el-button
                    @click="toggleTable2(false)"
                    size="mini"
                    :class="!showTable2 && 'btnActive'"
                    >图形</el-button
                  >
                  <el-button
                    @click="toggleTable2(true)"
                    size="mini"
                    :class="showTable2 && 'btnActive'"
                    >表格</el-button
                  >
                </el-button-group>
              </div>
            </div>
          </div>
          <!--简版现金流量表-->
          <div class="pl-30px pt-3 pb-3 pr-30px">
            <div class="row" v-show="!showTable2">
              <div
                id="cashFlow"
                :style="{ height: '300px' }"
                class="w-100"
              ></div>
            </div>
            <div v-show="showTable2" class="mb_jian">
              <profitTable
                :tableData="reportData.Result.FC.content"
                :years="reportData.Result.FB.year"
                :report_time="report_time"
              ></profitTable>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import liaTable from "../Table/liabilitiesTable";
import Pie_data from "@/components/chart/FinancialReportPie";
import Bar_data from "@/components/chart/FinancialReportBar";
import Bar from "@/components/chart/Bar";
import mytable from "../Table/FinancialTable";
import profitTable from "../Table/profitTable";
import cashTable from "../Table/cashTable";
import totalTendency from "./FinancialReportComponents/totaltendency.vue";
import totalCheck from "./FinancialReportComponents/totalCheck";
import * as sesstion from "../../store/localStorage";
import FunnelOne from "./FunnelOne";
import FunnelTwo from "./FunnelTwo";
import FunnelThree from "./FunnelThree";
import { simpleAnalysis, causeSimple, checkpoint, logActivity } from "@/api";

export default {
  name: "FinancialReport",
  components: {
    FunnelOne,
    FunnelTwo,
    FunnelThree,
    myTable: mytable,
    profitTable: profitTable,
    cashTable: cashTable,
    totalCheck: totalCheck,
    totalTendency,
    Pie_data,
    Bar_data,
    Bar,
    PublicOutsider: require("@/components/publicOutsider/publicOutsider.vue")
      .defaul,
    liaTable,
  },
  data() {
    return {
      maxHigh: 0,
      maxHighData: "",
      showTable: false,
      showTable1: false,
      showTable2: false,
      cashFlow: "",
      profitChart: "",
      isVIP: false,
      screenWidth: document.body.clientWidth,
      company: {},
      seriers: {},
      serier: [
        {
          color: "rgb(82, 158, 226)",
          title: "流动资产合计",
        },
        {
          color: "rgb(145, 198, 251)",
          title: "非流动资产合计",
        },
        {
          color: "rgb(192, 207, 255)",
          title: "流动负债合计",
        },
        {
          color: "rgb(250, 211, 63)",
          title: "非流动负债合计",
        },
        {
          color: "rgb(248, 142, 54)",
          title: "所有者权益(或股东权益)合计",
        },
      ],

      // 可查询的年份
      availableYears: [],
      RateArray: {},
      dataFunnelOne: [],
      dataFunnelTwo: [],
      dataFunnelThree: [],
      dataFunnelYearOne: [],
      dataFunnelYearTwo: [],
      dataFunnelYearThree: [],
      dataFunnelTitle: [],
      selectStart: 2015,
      selectEnd: 2017,
      selectedYear: 0,
      report_time: "00",
      report_type: "00",
      nowYear: 0,
      flag: false,
      reportData: {
        Result: {
          FB: {
            year: [],
            content: [
              {
                title: "资产总计",
                value: [],
                rate: [],
              },
              {
                title: "流动资产合计",
                value: [],
                rate: [],
              },
              {
                title: "非流动资产合计",
                value: [],
                rate: [],
              },
              {
                title: "流动负债合计",
                value: [],
                rate: [],
              },
              {
                title: "非流动负债合计",
                value: [],
                rate: [],
              },
              {
                title: "所有者权益（或股东权益）合计",
                value: [],
                rate: [],
              },
              {
                title: "负债和所有者权益（或股东权益）总计",
                value: [],
                rate: [],
              },
              {
                title: "固定资产",
                value: [],
                rate: [],
              },
            ],
            rpt_time_str: [],
          },
          FP: {
            year: [],
            content: [
              {
                title: "营业收入",
                value: [],
                compare: [],
              },
              {
                title: "营业成本",
                value: [],
                compare: [],
              },
              {
                title: "营业利润",
                value: [],
                compare: [],
              },
              {
                title: "利润总额",
                value: [],
                compare: [],
              },
              {
                title: "净利润",
                value: [],
                compare: [],
              },
              {
                title: "税金及附加",
                value: [],
                compare: [],
              },
              {
                title: "三项费用",
                value: [],
                compare: [],
              },
            ],
            rpt_time_str: [],
          },
          FC: {
            year: [],
            content: [
              {
                title: "销售商品、提供劳务收到的现金",
                value: [],
                compare: [],
              },
              {
                title: "经营活动现金流入",
                value: [],
                compare: [],
              },
              {
                title: "经营活动现金流出",
                value: [],
                compare: [],
              },
              {
                title: "经营活动产生的现金流量净额",
                value: [],
                compare: [],
              },
              {
                title: "购建固定资产、无形资产和其他长期资产支付的现金",
                value: [],
                compare: [],
              },
              {
                title: "投资支付的现金",
                value: [],
                compare: [],
              },
              {
                title: "投资活动产生的现金流量净额",
                value: [],
                compare: [],
              },
              {
                title: "吸收投资收到的现金",
                value: [],
                compare: [],
              },
              {
                title: "取得借款收到的现金",
                value: [],
                compare: [],
              },
              {
                title: "筹资活动产生的现金流量净额",
                value: [],
                compare: [],
              },
              {
                title: "现金及现金等价物净增加额",
                value: [],
                compare: [],
              },
              {
                title: "期末现金及现金等价物余额",
                value: [],
                compare: [],
              },
            ],
            rpt_time_str: [],
          },
        },
      },
      dateTime: "",
      date_tree: {},
      blankShow: true,
      unit: "万",
      unitText: "万",
      ArrayRet: [],
      ProfitArr: [],
      CashArr: [],

      state: 0.5,

      ArrList: [],
    };
  },
  mounted() {
    this.company = sesstion.getLocalData("company");
  },
  methods: {
    search(param) {
      this.paramsData = param;
      this.report_time = param.report_time;
      this.queryFinacialReport(param);
    },
    heightRate(data, nowRate) {
      let heightRate = nowRate;
      let tatol = 0;
      data.f.forEach((item, i) => {
        if (i > 1) {
          tatol += Math.abs(parseFloat(item.rate.split("%")));
        }
      });
      if (Math.round(tatol) > 100) {
        heightRate =
          (
            Math.abs(parseFloat(nowRate.split("%"))) / Math.round(tatol)
          ).toFixed(2) *
            100 +
          "%";
      }
      return heightRate;
    },
    saveUnit(obj) {
      //存贮用户选择的单位，防止刷新后还原
      sesstion.setLocalData("unitText", obj);
      this.unitText = sesstion.getLocalData("unitText");
    },
    setUnitValue(value) {
      sesstion.setLocalData("valueUnit", value);
      this.valueUnit = sesstion.getLocalData("valueUnit");
    },
    floatFixed(arr) {
      //保留两位小数
      return parseFloat(arr).toFixed(2);
    },
    forData(arr, obj, num) {
      for (let b = 0; b < obj.length; b++) {
        arr[b] = {};
        arr[b].value = [];
        arr[b].rate = [];
        arr[b].title = obj[b].title;
        for (var bk = 0; bk < obj[0].value.length; bk++) {
          arr[b].value[bk] = this.floatFixed(obj[b].value[bk] / num);
          arr[b].rate[bk] = obj[b].rate[bk] || obj[b].compare[bc];
        }
      }
    },
    forProfitData(arr, obj, num) {
      // console.log(arr, obj, num);
      for (let b = 0; b < obj.length; b++) {
        arr[b] = {};
        arr[b].value = [];
        arr[b].compare = [];
        arr[b].title = obj[b].title;
        for (var bk = 0; bk < obj[0].value.length; bk++) {
          arr[b].value[bk] = this.floatFixed(obj[b].value[bk] / num);
        }
        if (this.company.company_type >= "3") {
          for (var bc = 0; bc < obj[0].rate.length; bc++) {
            arr[b].compare[bc] = obj[b].rate[bc];
            //目前返回数据中"三项费用" 的compare为空
          }
        } else {
          for (var bc = 0; bc < obj[0].compare.length; bc++) {
            arr[b].compare[bc] = obj[b].compare[bc];
            //目前返回数据中"三项费用" 的compare为空
          }
        }
      }
    },
    toggleTable(falg) {
      this.showTable = falg;
    },
    toggleTable1(falg) {
      this.showTable1 = falg;
    },
    toggleTable2(falg) {
      this.showTable2 = falg;
    },
    queryFinacialReport(param) {
      var param = {
        ...param,
      };
      this.param = param;
      const company = sesstion.getLocalData("company");
      checkpoint({
        company_code: param.company_code,
        menu_id: "1",
        parsing_time: param.year,
        industry_type: param.industry_name
          ? param.industry_name.split("/").reverse()[0]
          : "",
        industry_number: param.industry_code,
        industry_range: "0",
        report_time: param.report_time,
        report_type: param.report_type,
      }).then(({ data }) => {
        if (data.Status !== "1") {
          this.$loadingHide(); //隐藏加载动画
          this.$alert(data.Message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        } else {
          this.$loadingShow(); //显示加载动画
          logActivity({
            company_code: param.company_code,
            menu_id: "1",
            parsing_time: param.year,
            industry_type: param.industry_name
              ? param.industry_name.split("/").reverse()[0]
              : "",
            industry_number: param.industry_code,
            industry_range: "0",
            report_time: param.report_time,
            report_type: param.report_type,
          });
          // 财务粉饰风险
          // console.log(param);
          if (this.getUserRole("overall_evaluation")) {
            this.$refs.totalCheck.Data(param);
          }

          if (this.getUserRole("Industry_trend")) {
            this.$refs.totalTendency.industryTrends(param);
          }

          if (company.company_type >= "3") {
            return causeSimple({
              ...param,
              report_year: this.$VM.PublicSearch.form_data.year,
            })
              .then((res) => {
                this.reportData = res.data;
                for (const key in res.data.Result) {
                  if (res.data.Result.hasOwnProperty(key)) {
                    const item = res.data.Result[key];
                    item.year.map((year, i) => {
                      item.year[i] = this.diffSynchronization(year);
                    });
                  }
                }
                let FB = res.data.Result.FB.content;
                let FP = res.data.Result.FP.content;
                let FC = res.data.Result.FC.content;

                // if (this.unit == "万") {
                //   //简版资产负债表,简版利润表,简版现金流量表
                //   this.saveUnit(this.unit);
                //   this.forData(this.ArrayRet, FB, 10000); //处理简版资产负债表的单位换算
                //   this.forProfitData(this.ProfitArr, FP, 10000);
                //   this.forProfitData(this.CashArr, FC, 10000);
                //   this.reportData.Result.FB.content = this.ArrayRet;
                //   this.reportData.Result.FP.content = this.ProfitArr;
                //   this.reportData.Result.FC.content = this.CashArr;
                // } else if (this.unit == "百万") {
                //   this.saveUnit(this.unit);
                //   this.forData(this.ArrayRet, FB, 1000000); //处理简版资产负债表的单位换算
                //   this.forProfitData(this.ProfitArr, FP, 1000000);
                //   this.forProfitData(this.CashArr, FC, 1000000);
                //   this.reportData.Result.FB.content = this.ArrayRet;
                //   this.reportData.Result.FP.content = this.ProfitArr;
                //   this.reportData.Result.FC.content = this.CashArr;
                // } else {
                //   this.saveUnit(this.unit);
                //   this.forData(this.ArrayRet, FB, 100000000); //处理简版资产负债表的单位换算
                //   this.forProfitData(this.ProfitArr, FP, 100000000);
                //   this.forProfitData(this.CashArr, FC, 100000000);
                //   this.reportData.Result.FB.content = this.ArrayRet;
                //   this.reportData.Result.FP.content = this.ProfitArr;
                //   this.reportData.Result.FC.content = this.CashArr;
                // }

                this.profitChartLine(this.reportData.Result.FP.content);
                this.cashFlowChart(this.reportData.Result.FC.content);
                this.fuzhai(this.reportData.Result.FB);
                this.showTable = false;
                this.showTable1 = false;
                this.showTable2 = false;
                this.$loadingHide();
              })

              .catch((err) => {
                console.error(err);
                this.$loadingHide();
              });
          }
          return simpleAnalysis(param)
            .then((res) => {
              if (res.data.Status != 1)
                return this.$Message.warning(res.data.Message);
              this.reportData = res.data;

              for (const key in res.data.Result) {
                if (res.data.Result.hasOwnProperty(key)) {
                  const item = res.data.Result[key];
                  item.year.map((year, i) => {
                    item.year[i] = this.diffSynchronization(year);
                  });
                }
              }
              let FB = res.data.Result.FB.content;
              let FP = res.data.Result.FP.content;
              let FC = res.data.Result.FC.content;

              // if (this.unit == "万") {
              //   //简版资产负债表,简版利润表,简版现金流量表
              //   this.saveUnit(this.unit);
              //   this.forData(this.ArrayRet, FB, 10000); //处理简版资产负债表的单位换算
              //   this.forProfitData(this.ProfitArr, FP, 10000);
              //   this.forProfitData(this.CashArr, FC, 10000);
              //   this.reportData.Result.FB.content = this.ArrayRet;
              //   this.reportData.Result.FP.content = this.ProfitArr;
              //   this.reportData.Result.FC.content = this.CashArr;
              // } else if (this.unit == "百万") {
              //   this.saveUnit(this.unit);
              //   this.forData(this.ArrayRet, FB, 1000000); //处理简版资产负债表的单位换算
              //   this.forProfitData(this.ProfitArr, FP, 1000000);
              //   this.forProfitData(this.CashArr, FC, 1000000);
              //   this.reportData.Result.FB.content = this.ArrayRet;
              //   this.reportData.Result.FP.content = this.ProfitArr;
              //   this.reportData.Result.FC.content = this.CashArr;
              // } else {
              //   this.saveUnit(this.unit);
              //   this.forData(this.ArrayRet, FB, 100000000); //处理简版资产负债表的单位换算
              //   this.forProfitData(this.ProfitArr, FP, 100000000);
              //   this.forProfitData(this.CashArr, FC, 100000000);
              //   this.reportData.Result.FB.content = this.ArrayRet;
              //   this.reportData.Result.FP.content = this.ProfitArr;
              //   this.reportData.Result.FC.content = this.CashArr;
              // }
              // console.log(this.reportData);

              this.profitChartLine(this.reportData.Result.FP.content);
              this.cashFlowChart(this.reportData.Result.FC.content);
              this.fuzhai(this.reportData.Result.FB);
              this.showTable = false;
              this.showTable1 = false;
              this.showTable2 = false;
              this.$loadingHide();
            })

            .catch((err) => {
              console.error(err);
              this.$loadingHide();
            });
        }
      });
    },

    fuzhai(data) {
      this.ArrList = [];
      let one = [];
      let two = [];
      let thr = [];
      let color = [
        ["#F2637B", "#FAD33F", "#87CA6B", "#529EE2", "none"],
        ["#7E98FE", "#F88E36", "#3EBB9F", "#91C6FB", "none"],
      ];
      data.content.map((item, index) => {
        if (index == 0 || index == 1) {
          // console.log(item, index);
          if (item.length == 2) {
            item.map((a, i) => {
              // console.log(a,i);
              if (i == 0) {
                one.push(a);
              }
              if (i == 1) {
                two.push(a);
              }
            });
          } else {
            item.map((a, i) => {
              // console.log(a,i);
              if (i == 0) {
                one.push(a);
              }
              if (i == 1) {
                two.push(a);
              }
              if (i == 2) {
                thr.push(a);
              }
            });
          }
        } else {
          if (item[0].value.length == 2) {
            one.push([item[0].value[0]]);
            two.push([item[0].value[1]]);
          } else {
            one.push([item[0].value[0]]);
            two.push([item[0].value[1]]);
            thr.push([item[0].value[2]]);
          }
        }
      });
      if (thr.length == 0) {
        this.ArrList.push(one, two);
      } else {
        this.ArrList.push(one, two, thr);
      }
      this.ArrList.map((item, index) => {
        item.map((j, k) => {
          if (k == 0) {
            let obj = {
              value:
                Number(j[0].value) +
                Number(j[1].value) +
                Number(j[2].value) +
                Number(j[3].value),
              itemStyle: {
                color: "none",
              },
              label: {
                show: false,
              },
              name: "资产",
            };
            j.push(obj);
            j.map((i, x) => {
              i.name = i.title;
              i.itemStyle = {
                color: color[0][x],
              };
            });
          }
          if (k == 1) {
            let obj = {
              value:
                Number(j[0].value) +
                Number(j[1].value) +
                Number(j[2].value) +
                Number(j[3].value),
              itemStyle: {
                color: "none",
              },
              label: {
                show: false,
              },
              name: "负债和所有者权益",
            };
            j.push(obj);
            j.map((i, x) => {
              i.name = i.title;
              i.itemStyle = {
                color: color[1][x],
              };
            });
          }
        });
      });
    },

    // 利润表漏斗
    profitChartLine(data) {
      data.map((item, index) => {
        if (index !== 0 && index !== 6 && index !== 8) {
          this.dataFunnelOne.push({
            value: item.value[0],
            name: item.title,
            compare: item.compare[0],
          });
          this.dataFunnelTwo.push({
            value: item.value[1],
            name: item.title,
            compare: item.compare[1],
          });
          if (item.value[2] == undefined) {
            this.flag = false;
            this.dataFunnelThree.push({
              value: item.value[2],
              name: item.title,
              compare: item.compare[2],
            });
          } else {
            this.flag = true;
            this.dataFunnelThree.push({
              value: item.value[2],
              name: item.title,
              compare: item.compare[2],
            });
          }
        } else {
          this.dataFunnelOne.push({
            value: item.value[0],
            name: item.title,
            compare: item.compare[0],
            itemStyle: {
              color: "#529ee2",
            },
          });
          this.dataFunnelTwo.push({
            value: item.value[1],
            name: item.title,
            compare: item.compare[1],
            itemStyle: {
              color: "#529ee2",
            },
          });
          this.dataFunnelThree.push({
            value: item.value[2],
            name: item.title,
            compare: item.compare[2],
            itemStyle: {
              color: "#529ee2",
            },
          });
        }
      });
      this.dataFunnelOne.reverse();
      this.dataFunnelTwo.reverse();
      this.dataFunnelThree.reverse();

      this.dataFunnelYearOne = this.reportData.Result.FB.rpt_time_str[0];
      this.dataFunnelYearTwo = this.reportData.Result.FB.rpt_time_str[1];
      this.dataFunnelYearThree = this.reportData.Result.FB.rpt_time_str[2];
    },
    // 现金流
    cashFlowChart(data) {
      let FC = data;
      let TC = [];
      let FColor = ["#529EE2", "#F88E36", "#91C6FB"];
      let TCData = [];
      let CSeries = [];
      let Cv = [];
      let Cg = [];
      if (this.company.company_type >= "3") {
        TC[0] = FC[0];
        TC[1] = FC[3];
        TC[2] = FC[6];
      } else {
        TC[0] = FC[3];
        TC[1] = FC[6];
        TC[2] = FC[9];
      }
      var TCL = TC.length;
      for (var t = 0; t < TCL; t++) {
        TCData[t] = TC[t].title;
        Cg[t] = [];
        Cv[t] = TC[t].value;
        for (var g = 0; g < Cv[0].length; g++) {
          Cg[t][g] = Cv[t][g];
        }
        CSeries.push({
          name: TC[t].title,
          type: "bar",
          barWidth: 40,
          data: Cg[t],
          itemStyle: {
            normal: {
              color: FColor[t],
            },
          },
        });
      }
      if (this.report_time == "00") {
        this.dateTime = "年度";
      }
      if (this.report_time == "01") {
        this.dateTime = "三季度";
      }
      if (this.report_time == "02") {
        this.dateTime = "半年度";
      }
      if (this.report_time == "03") {
        this.dateTime = "一季度";
      }

      this.cashFlow = this.$echarts.init(document.getElementById("cashFlow"));
      this.cashFlow.setOption({
        tooltip: {
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: TCData,

          itemGap: 5,
          textStyle: {
            fontSize: 14,
          },
        },
        grid: {
          left: "1%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.reportData.Result.FB.rpt_time_str,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：" + this.unitText + "元",
          },
        ],
        series: CSeries,
      });
    },
    changeUnit() {
      //单位改变调用
      this.queryFinacialReport(this.param);
    },
    thousandBitSeparator: function (num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
    diffSynchronization(year) {
      return year + "年";
    },
  },
  computed: {
    getRateData: function () {
      let set = [];
      // console.log(this.reportData.Result.FB.content);
      let u = this.reportData.Result.FB.content;
      if (this.company.company_type >= "3") {
        set[0] = u[1];
        set[1] = u[2];
        set[2] = u[4];
        set[3] = u[5];
      } else {
        set[0] = u[0];
        set[1] = u[1];
        set[2] = u[3];
        set[3] = u[4];
      }
      this.RateArray = set;
      var c = u[2].value;
      var year = this.reportData.Result.FB.rpt_time_str;
      var YL = year.length;
      var RL = set.length;
      this.colorArray = ["#529EE2", "#91C6FB", "#C0CFFF", "#FAD33F", "#F88E36"];
      let a = [];
      let b = [];
      let d = [];
      var writerInfo = {};
      for (var i = 0; i < YL; i++) {
        a[i] = {};
        a[i].f = [];
        for (var j = 0; j < RL; j++) {
          a[i].f[j] = {};
          this.seriers[j] = {};
          a[i].total = c[i];
          a[i].year = year[i];
          a[i].f[j].title = set[j].title;
          a[i].f[j].rate = set[j].rate[i];
          a[i].f[j].color = this.colorArray[j];
          this.seriers[j].color = this.colorArray[j];
          this.seriers[j].title = this.RateArray[j].title;
        }
      }
      a.forEach((v, i) => {
        const num = 100 - parseFloat(v.f[0].rate.split("%")[0]);
        v.f[1].rate = Math.round(num * 100) / 100 + "%";
      });
      return a;
    },
  },
  created() {
    this.$VM[this.$route.name] = this;
    this.$store.state.showNav = true;
  },
};
</script>

<style lang="less" scoped>
.el-button {
  font-size: 14px;
}

.legend {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;

  .end {
    display: flex;
    align-items: center;

    .leg {
      margin-left: 10px;
      margin-right: 5px;
      width: 24px;
      height: 14px;
      border-radius: 3px;
    }
  }
}

.title {
  font-size: 20px;
  font-weight: 550;
  background: #fff;
  padding: 15px 20px 10px 20px;
  color: #333;
}

.title-p {
  padding: 10px 8px;
  line-height: 32px;

  > div:nth-child(1) {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 550;
    opacity: 1;
    position: relative;
    padding-left: 30px;
    color: #333;
  }
}

.btn-toggle-from {
  width: 40px;
  height: 30px;
}

.table-chart {
  display: flex;
  align-items: center;
}

.mt-2px {
  margin-top: 2px !important;
}

.ml-2px {
  margin-left: 2px !important;
}

.mlr-4px {
  margin: 0 4px !important;
}

.h-160px {
  height: 220px;
}

.h40 {
  height: 40px;
}

.h50 {
  height: 50px;
}

.h55 {
  height: 55px;
}

.h60 {
  height: 60px;
}

.h70 {
  height: 70px;
}

.w-95px {
  width: 140px;
}

.lend {
  width: 24px;
  height: 14px;
  border-radius: 3px;
  margin-left: 15px;
}

.btnActive {
  color: #fff;
  border: 1px solid #3a85fd;
  background: #3a85fd;
}

.color-1f {
  font-size: 24px;
}

.table-eachrt-box {
  padding: 2px 3px;
  background: rgba(217, 217, 217, 1);
  opacity: 0.29;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;

  > div:nth-child(1) {
    margin-right: 8px;
  }

  > div {
    padding: 2px;
    background: rgba(217, 217, 217, 1);
    width: 38px;
    text-align: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 460px) {
  .mb_box_ch {
    flex-direction: column;
  }

  .mb_tab_ec {
    display: none;
  }

  .mb_count_ch {
    flex: 1;

    .w-95px {
      width: 100%;
    }
  }

  // .mb_jian {
  //   display: none;
  // }
}
</style>
