<template>
  <div class="table-responsive-sm" style="color:#333;font-size:16px;">
    <table class="table text-center table-striped" style="color:#333;font-size:16px;">
      <thead class="bgc-be color-0">
        <tr>
          <th rowspan="2" valign="middle" align="center" scope="row">项目</th>
          <th colspan="2" v-for="cell in years" :key="cell">{{cell}}</th>
        </tr>
        <tr>
          <th style="background:#F6FAFF;" v-for="(cell,index) in secondLine.titleItem" :key="index" :colspan=" index == 0 ? 2: 1 ">{{ cell}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in tableData"
            :key="index"
            class="color-4d">
          <td scope="row" class="text-left">{{data.title }}</td>
          <td colspan="2">{{ data.value[0]}}</td>
          <td>{{ data.value[1]}}</td>
          <td>{{ data.compare[1] }}</td>
          <td>{{ data.value[2]}}</td>
          <td>{{data.compare[2]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: Array,
    years: Array
  },
  data() {
    return {
      secondLine: {
        titleItem: ["金额", "金额", "同比增减", "金额", "同比增减"]
      }
    };
  },

  computed: {
    s: function(index) {
      return 2;
    }
  }
};
</script>

<style>
.table thead th {
  vertical-align: middle !important;
  border: 0px solid transparent;
}
.table td,.table th{
  border-top: 0px solid transparent;
}
</style>
