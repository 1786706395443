<template>
  <div style="margin-top: 15px; background: #fff; padding-bottom: 20px">
    <div class="title">
      <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
      行业走势
    </div>
    <div class="ml-4">
      <el-button-group>
        <el-button
          style="font-size: 14px"
          v-for="item in list"
          :key="item.key"
          size="small"
          :type="active == item.key ? 'primary' : ''"
          @click="TotalFive(item.key)"
          >{{ item.name }}</el-button
        >
      </el-button-group>
    </div>

    <div class="chartTendency" ref="chartTendency"></div>
  </div>
</template>
<script>
import * as sesstion from "@/store/localStorage";
import { industry_trends } from "@/api.js";
import { dataList } from "./tendency.js";
export default {
  name: "totaltendency",
  components: {},
  data() {
    return {
      active: "0",
      list: [
        {
          name: "盈利能力",
          key: 0,
        },
        {
          name: "营运能力",
          key: 1,
        },
        {
          name: "发展能力",
          key: 2,
        },
        {
          name: "短期偿债能力",
          key: 3,
        },
        {
          name: "长期偿债能力",
          key: 4,
        },
      ],
      year_list: [],
      arr: [],
      dataObj: {},
      trends: [],
      legendData: [],
    };
  },
  created() {
    this.arr = dataList;
  },
  mounted() {
    // this.Data();
  },
  methods: {
    industryTrends(params) {
      let param = {
        ...params,
        industry_range: "0",
        report_year: params.year,
      };
      industry_trends(param).then(({ data }) => {
        this.year_list = data.year_list;
        this.dataObj = data.data;
        this.TotalFive(0);
        this.Data();
      });
    },

    TotalFive(type) {
      this.active = type;
      this.legendData = [];
      this.trends = [];
      if (type == 0) {
        for (const index in this.arr[0].res) {
          this.legendData.push(this.arr[0].res[index].name);
          for (const key in this.dataObj) {
            if (key == this.arr[0].res[index].name) {
              this.arr[0].res[index].data = this.dataObj[key].data;
            }
          }
        }
        this.trends = this.arr[0].res;
        this.Data();
      } else if (type == 1) {
        for (const index in this.arr[1].res) {
          this.legendData.push(this.arr[1].res[index].name);
          for (const key in this.dataObj) {
            if (key == this.arr[1].res[index].name) {
              this.arr[1].res[index].data = this.dataObj[key].data;
            }
          }
        }
        this.trends = this.arr[1].res;
        this.Data();
      } else if (type == 2) {
        for (const index in this.arr[2].res) {
          this.legendData.push(this.arr[2].res[index].name);
          for (const key in this.dataObj) {
            if (key == this.arr[2].res[index].name) {
              this.arr[2].res[index].data = this.dataObj[key].data;
            }
          }
        }
        this.trends = this.arr[2].res;
        this.Data();
      } else if (type == 3) {
        for (const index in this.arr[3].res) {
          this.legendData.push(this.arr[3].res[index].name);
          for (const key in this.dataObj) {
            if (key == this.arr[3].res[index].name) {
              this.arr[3].res[index].data = this.dataObj[key].data;
            }
          }
        }
        this.trends = this.arr[3].res;
        this.Data();
      } else {
        for (const index in this.arr[4].res) {
          this.legendData.push(this.arr[4].res[index].name);
          for (const key in this.dataObj) {
            if (key == this.arr[4].res[index].name) {
              this.arr[4].res[index].data = this.dataObj[key].data;
            }
          }
        }
        this.trends = this.arr[4].res;
        this.Data();
      }
    },
    Data() {
      this.$echarts.init(this.$refs.chartTendency).dispose();
      var option = {
        // title: {
        //   text: "Stacked Line",
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.legendData,
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.year_list,
        },
        yAxis: [
          {
            type: "value",
            name: "%",
            position: "left",
            alignTicks: true,
            axisLine: {
              show: true,
              // lineStyle: {
              //   color: '#333'
              // }
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "倍数/次",
            position: "right",
            alignTicks: true,
            // offset: 80,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#333",
              },
            },
            axisLabel: {
              formatter: (value)=>{
                return value.toFixed(2);
              },
            },
          },
        ],
        series: this.trends,
      };
      this.$echarts.init(this.$refs.chartTendency).setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 15px 0px 10px 20px;
  font-size: 20px;
  font-weight: 550;
  background: #fff;
  color: #333;
}
.chartTendency {
  margin-top: 20px;
  width: 100%;
  height: 350px;
}

@media (max-width: 460px) {
}
</style>
