<template>
    <div style="width:100%" class="row">
            <el-table class="row" v-for="(item, index) in arr" :key="index" :data="item" :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
                style="width: 33%;font-size: 16px;">
                <!-- :row-style="{height:'80px'}" -->
                <el-table-column :label="year[index]" align="center">
                    <el-table-column prop="name" label="项目" width="200">
                        <template slot-scope="{row}">
                            <span :style="{ color: row.color }">{{ row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="value" label="金额(万元)">
                        <template slot-scope="{row}">
                            <span :style="{ color: row.color }">{{ thousandBitSeparator(row.value) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rate" label="占比" width="100">
                        <template slot-scope="{row}">
                            <span :style="{ color: row.color }">{{ row.rate }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
        <!-- <el-table :data="arr[1]" :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
            style="width: 100%;font-size: 16px;">
            <el-table-column :label="year[1]" align="center">
                <el-table-column prop="name" label="项目" width="240">
                    <template slot-scope="{row}">
                        <span :style="{ color: row.color }">{{ row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="value" label="金额">
                    <template slot-scope="{row}">
                        <span :style="{ color: row.color }">{{ thousandBitSeparator(row.value) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="rate" label="占比" width="90">
                    <template slot-scope="{row}">
                        <span :style="{ color: row.color }">{{ row.rate }}</span>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
        <el-table :data="arr[2]" :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
            style="width: 100%;font-size: 16px;">
            <el-table-column :label="year[2]" align="center">
                <el-table-column prop="name" label="项目" width="240">
                    <template slot-scope="{row}">
                        <span :style="{ color: row.color }">{{ row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="value" label="金额">
                    <template slot-scope="{row}">
                        <span :style="{ color: row.color }">{{ thousandBitSeparator(row.value) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="rate" label="占比" width="90">
                    <template slot-scope="{row}">
                        <span :style="{ color: row.color }">{{ row.rate }}</span>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table> -->
    </div>
</template>

<script>
export default {
    props: {
        dataList: Array,
        year: Array,
    },
    watch: {
        dataList() {
            this.getTable()
        }
    },
    data() {
        return {
            arr: []
        }
    },
    methods: {
        getTable() {
            // console.log(this.dataList);
            this.arr=[]
            this.dataList.map((item, index) => {
                delete item[2]
                item[0][4].name = '资产'
                item[1][4].name = '负债和所有者权益'
                item[0].unshift(item[0][4])
                item[1].unshift(item[1][4])
                delete item[0][5]
                delete item[1][5]
                delete item[0][0].value
                delete item[1][0].value
                // console.log(item, index);
                var data = item[0].concat(item[1]);
                // console.log(data);
                this.arr.push(data)
            })
        },
        thousandBitSeparator: function (num) {
            return (
                num &&
                (num.toString().indexOf(".") != -1
                    ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
                        return $1 + ",";
                    })
                    : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
                        return $1 + ",";
                    }))
            );
        },
    },
}
</script>

<style>

</style>