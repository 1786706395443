<template>
  <div>
    <div id="FB_bar" ref="bar"></div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: Object,
  },
  watch: {
    dataList() {
      // setTimeout(() => {
      this.getDataBar();
      // }, 1000);
    },
  },
  mounted() {
    this.getDataBar();
  },
  methods: {
    thousandBitSeparator: function (num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
    getDataBar() {
      var that = this;
      let list = [];
      let one = [];
      let two = [];
      let three = [];
      let four = [];
      list.push(this.dataList[0], this.dataList[1]);

      one.push(list[0][0], list[1][0]);
      two.push(list[0][1], list[1][1]);
      three.push(list[0][2], list[1][2]);
      four.push(list[0][3], list[1][3]);
      var option = {
        legend: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          formatter: (item) => {
            return `${
              item[0].axisValue
            }<div style='display:flex; align-items: center;justify-content: space-between;'><span>${
              item[0].marker
            }${item[0].name}：</span><span>${that.thousandBitSeparator(
              item[0].value
            )}</span></div><div style='display:flex; align-items: center;justify-content: space-between;'><span>${
              item[1].marker
            }${item[1].name}：</span><span>${that.thousandBitSeparator(
              item[1].value
            )}</span></div><div style='display:flex; align-items: center;justify-content: space-between;'><span>${
              item[2].marker
            }${item[2].name}：</span><span>${that.thousandBitSeparator(
              item[2].value
            )}</span></div><div style='display:flex; align-items: center;justify-content: space-between;'><span>${
              item[3].marker
            }${item[3].name}：</span><span>${that.thousandBitSeparator(
              item[3].value
            )}</span></div>`;
          },
        },
        grid: {
          top: "3%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["资产", "负债和所有者权益"],
          },
        ],
        yAxis: [
          {
            show: false,
            type: "value",
          },
        ],
        series: [
          {
            type: "bar",
            stack: "total",
            barWidth: 80,
            label: {
              show: true,
              formatter(param) {
                return param.data.rate;
              },
            },

            data: one,
          },
          {
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter(param) {
                return param.data.rate;
              },
            },
            data: two,
          },
          {
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter(param) {
                return param.data.rate;
              },
            },
            data: three,
          },
          {
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter(param) {
                return param.data.rate;
              },
            },
            data: four,
          },
        ],
      };
      this.$echarts.init(this.$refs.bar).setOption(option);
    },
  },
};
</script>

<style>
#FB_bar {
  width: 100%;
  height: 300px;
}
</style>