export const dataList = [
    {
        name: "盈利能力",
        res: [
            {
                name: "成本费用占主营业务收入比重（%）",
                // stack: "Total",
                data: [96, 96.4, 92.7, 93.2, 87.5, 87.7],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "净资产收益率（%）",
                // stack: "Total",
                data: [9.1, 10, 9.7, 9.5, 9.3, 8.8],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "总资产报酬率（%）",
                // stack: "Total",
                data: [5.5, 5.9, 6.1, 6.5, 7.2, 6.3],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "盈余现金保障倍数",
                // stack: "Total",
                data: [0.7, 0.8, 0.8, 1.2, 2.1, 0.8],
                type: "line",
                yAxisIndex: 1,
            },
            {
                name: "资本收益率（%）",
                // stack: "Total",
                data: [6.3, 6.3, 7.3, 6.7, 4.1, 9.4],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "销售（营业）利润率（%）",
                // stack: "Total",
                data: [10.9, 8.3, 7.3, 8, 14.5, 17.7],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "成本费用利润率（%）",
                // stack: "Total",
                data: [6.5, 6.5, 7.1, 8.7, 16, 16.3],
                type: "line",
                yAxisIndex: 0,
            },
        ],
    },
    {
        name: "营运能力",
        res: [
            {
                name: "存货周转率（次）",
                // stack: "Total",
                data: [2.7, 2.9, 2.7, 2.9, 2.7, 2.7],
                type: "line",
                yAxisIndex: 1,
            },
            {
                name: "流动资产周转率（次）",
                // stack: "Total",
                data: [1, 1.3, 1.1, 1.5, 1.5, 1.5],
                type: "line",
                yAxisIndex: 1,
            },
            {
                name: "应收账款周转率（次）",
                // stack: "Total",
                data: [4.6, 4.3, 4.1, 5, 5, 5.3],
                type: "line",
                yAxisIndex: 1,
            },
            {
                name: "总资产周转率（次）",
                // stack: "Total",
                data: [0.5, 0.5, 0.5, 0.9, 1, 0.5],
                type: "line",
                yAxisIndex: 1,
            },
        ],
    },
    {
        name: "发展能力",
        res: [
            {
                name: "资本积累率（%）",
                // stack: "Total",
                data: [7.9, 7.9, 8.6, 9.3, 12.1, 9.3],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "销售（营业）利润增长率（%）",
                // stack: "Total",
                data: [9.1, 9.1, -1.3, 7.2, 9, 8.4],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "总资产增长率（%）",
                // stack: "Total",
                data: [5.1, 5.1, 4.3, 8.5, 8.7, 8.5],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "销售（营业）增长率（%）",
                // stack: "Total",
                data: [7.6, 12.3, 0.5, 10.4, 8.9, 8.2],
                type: "line",
                yAxisIndex: 0,
            },
        ],
    },
    {
        name: "短期偿债能力",
        res: [
            {
                name: "速动比率（%）",
                // stack: "Total",
                data: [90, 85.2, 82.2, 78.3, 69.3, 86.1],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "现金流动负债比率（%）",
                // stack: "Total",
                data: [4.3, 6, 6.1, 6.5, 6.4, 8.1],
                type: "line",
                yAxisIndex: 0,
            },
        ],
    },
    {
        name: "长期偿债能力",
        res: [
            {
                name: "资产负债率（%）",
                // stack: "Total",
                data: [58, 58, 58.3, 58.6, 59, 59.5],
                type: "line",
                yAxisIndex: 0,
            },
            {
                name: "已获利息倍数",
                // stack: "Total",
                data: [4.5, 3.9, 4, 3.8, 3.7, 3.5],
                type: "line",
                yAxisIndex: 1,
            },
            {
                name: "带息负债比率（%）",
                // stack: "Total",
                data: [31.7, 32.2, 34, 34.6, 37.1, 37.6],
                type: "line",
                yAxisIndex: 0,
            },
        ],
    },
];

