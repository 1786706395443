<template>
  <div style="margin-top: 15px; background: #fff; padding-bottom: 20px">
    <div class="title">
      <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
      报表总体评价
    </div>
    <div class="box_fen">
      <div class="c_fen_xi">
        <div class="row justify-content-between pr-4">
          <div
            style="
              margin-left: 20px;
              font-weight: 550;
              font-size: 18px;
              color: #333;
              display: flex;
              align-items: center;
            "
          >
            风险评估结论
            <el-tag
              class="ml-2"
              style="color: #fff; font-size: 14px; border-color: #3ebb9f"
              color="#3EBB9F"
              v-show="grade.risk == '风险低'"
              >风险低</el-tag
            >
            <el-tag
              class="ml-2"
              style="color: #fff; font-size: 14px; border-color: #f88e36"
              color="#F88E36"
              v-show="grade.risk == '风险中'"
              >风险中</el-tag
            >
            <el-tag
              class="ml-2"
              style="color: #fff; font-size: 14px; border-color: #ee5043"
              color="#EE5043"
              v-show="grade.risk == '风险高'"
              >风险高</el-tag
            >
          </div>
          <div class="row align-items-center">
            <el-tooltip
              class="item"
              effect="dark"
              content="上年同期风险评估结果"
              placement="bottom"
            >
              <div class="p_box" style="background:#D8E7FF; color: #3A85FD;}">
                {{ last_grade.risk }}
              </div>
            </el-tooltip>
            <div
              class="p_box ml-1 mr-1"
              style="background: #a6d0fe; color: #fff"
            >
              <img
                src="~@/assets/img/page/right.png"
                alt=""
                srcset=""
                style="width: 30px; height: 20px"
              />
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              content="当期风险评估结果"
              placement="bottom"
            >
              <div class="p_box" style="background: #5797fd; color: #fff">
                {{ grade.risk }}
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="text-center">
          <img
            v-show="grade.grade == 'A'"
            src="@/assets/img/detail/A.png"
            alt=""
          />
          <img
            v-show="grade.grade == 'A-'"
            src="@/assets/img/detail/A-.png"
            alt=""
          />
          <img
            v-show="grade.grade == 'A+'"
            src="@/assets/img/detail/A+.png"
            alt=""
          />
          <img
            v-show="grade.grade == 'B'"
            src="@/assets/img/detail/B.png"
            alt=""
          />
          <img
            v-show="grade.grade == 'B-'"
            src="@/assets/img/detail/B-.png"
            alt=""
          />
          <img
            v-show="grade.grade == 'B+'"
            src="@/assets/img/detail/B+.png"
            alt=""
          />
          <img
            v-show="grade.grade == 'C'"
            src="@/assets/img/detail/C.png"
            alt=""
          />
          <img
            v-show="grade.grade == 'C-'"
            src="@/assets/img/detail/C-.png"
            alt=""
          />
          <img
            v-show="grade.grade == 'C+'"
            src="@/assets/img/detail/C+.png"
            alt=""
          />
        </div>
      </div>
      <div class="c_fen_xi">
        <div class="row justify-content-between pr-4">
          <div
            style="
              margin-left: 20px;
              font-weight: 550;
              font-size: 18px;
              color: #333;
            "
          >
            能力分析结果
          </div>
          <div class="row align-items-center">
            <el-tooltip
              class="item"
              effect="dark"
              content="上年同期能力分析结果"
              placement="bottom"
            >
              <div class="p_box" style="background:#D8E7FF; color: #3A85FD;}">
                {{ last_desc }}
              </div>
            </el-tooltip>
            <div
              class="p_box ml-1 mr-1"
              style="background: #a6d0fe; color: #fff"
            >
              <img
                src="~@/assets/img/page/right.png"
                alt=""
                srcset=""
                style="width: 30px; height: 20px"
              />
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              content="当期能力分析结果"
              placement="bottom"
            >
              <div class="p_box" style="background: #5797fd; color: #fff">
                {{ pie_desc }}
              </div>
            </el-tooltip>
          </div>
        </div>
        <s-pie
          :data="pie_data"
          :name="pie_name"
          :desc="pie_desc"
          :raduis="120"
        ></s-pie>
      </div>
    </div>
    <div class="textDesc">
      {{ grade.desc }}
    </div>
  </div>
</template>
<script>
import { proFile_fin } from "@/api.js";
import * as sesstion from "@/store/localStorage";
import { pie_data_fin } from "@/api/common";

export default {
  name: "totalCheck",
  components: {
    SPie: require("@/components/SPie/index.vue").default,
    // outsider: require("./components/outsider.vue").default,
    // Gauge: require("@/components/gauge/index").default,
    // EchartLine: require("@/components/line/index").default,
    // SecurityLine: require("@/components/line/SecurityLine").default,
  },
  data() {
    return {
      pie_data: [],
      pie_name: "-",
      pie_desc: "-",
      last_desc: "-",
      grade: {},
      last_grade: {},
      companyInfo: sesstion.getLocalData("company"),
    };
  },

  methods: {
    Data(param) {
      this.set_pie_data(param);
      proFile_fin(param).then(({ data }) => {
        this.grade = data.grade;
        this.last_grade = data.grade_last;
      });
    },
    set_pie_data(data) {
      pie_data_fin({
        ...data,
        industry_range: "0",
        report_year: data.year,
        company_name: data.name,
      }).then(({ data }) => {
        this.pie_data = data.data;
        this.pie_name = data.name;
        this.pie_desc = data.desc;
        this.last_desc = data.last_desc;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tag {
  display: inline-block;
  margin-left: 10px;
  border: 0;
  width: 68px;
  height: 24px;
  background: #3a85fd;
  color: #fff;
  text-align: center;
  border-radius: 4px;
}
.textDesc {
  background: #e9f3ff;
  width: 97%;
  min-height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #333;
  padding: 10px;
  margin: 10px 20px 0px 20px;
}
.title {
  padding: 15px 0px 10px 20px;
  font-size: 20px;
  font-weight: 550;
  background: #fff;
  color: #333;
}

.box_fen {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.c_fen {
  width: 49%;
  /* min-height: 90px; */
  height: 360px;
  /* padding: 18px; */
  background: #fff;
  /* border: 1px solid rgba(0,0,0) !important; */

  .pj {
    position: absolute;
    bottom: 95px;
    left: 23.2%;
    font-size: 24px;
    color: #000;
    font-weight: 550;
  }
}

.c_fen_xi {
  width: 49.5%;
  min-height: 90px;
  /* padding: 18px; */
  background: #fff;
  border: 1px solid rgba(112, 112, 112, 0);

  .p_box {
    border: none;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    width: 60px;
    height: 20px;
    transform: skew(-25deg);
    border-radius: 3px;
  }
  .text-center {
    img {
      width: 35%;
    }
  }
}

@media (max-width: 460px) {
  .logo_hua {
    height: 55px;
  }

  .box_fen {
    display: flex;
    flex-direction: column;
  }

  .c_fen {
    margin-bottom: 5px;
  }

  .c_fen,
  .c_fen_xi {
    width: 96%;
  }
}
</style>
