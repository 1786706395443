<template>
  <div id="Funnel" ref="Funnel"></div>
</template>

<script>
export default {
  props: {
    dataList: Array,
    yearList: Array,
    report_time:String,
  },
  data() {
    return {};
  },
  watch: {
    dataList() {
      this.init();
    },
  },
  // mounted() {
  //   this.init();
  // },
  computed: {
    s: function (index) {
      return 2;
    },
  },
  methods: {
    thousandBitSeparator: function (num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
    init() {
      let param = [];
       let paras = { value: this.dataList[0], name: this.thousandBitSeparator(this.dataList[0])};
      let paras1 = { value: this.dataList[1], name: this.thousandBitSeparator(this.dataList[1])};
      let paras2 = { value: this.dataList[2], name: this.thousandBitSeparator(this.dataList[2])};
      let paras3 = { value: this.dataList[3], name: this.thousandBitSeparator(this.dataList[3])};
      param.push(paras, paras1, paras2, paras3);
      // console.log(param);
      function numFormat(num) {
        var res = num.toString().replace(/\d+/, function (n) {
          // 先提取整数部分
          return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
            return $1 + ",";
          });
        });
        return res;
      }
      if(this.report_time=='00'){
        this.report_time='年度'
      }
      if(this.report_time=='01'){
        this.report_time='三季度'
      }
      if(this.report_time=='02'){
        this.report_time='半年度'
      }
      if(this.report_time=='03'){
        this.report_time='一季度'
      }
      if(this.report_time=='001'){
        this.report_time='一月报'
      }
      if(this.report_time=='002'){
        this.report_time='二月报'
      }
      if(this.report_time=='003'){
        this.report_time='三月报'
      }
      if(this.report_time=='004'){
        this.report_time='四月报'
      }
      if(this.report_time=='005'){
        this.report_time='五月报'
      }
      if(this.report_time=='006'){
        this.report_time='六月报'
      }
      if(this.report_time=='007'){
        this.report_time='七月报'
      }
      if(this.report_time=='008'){
        this.report_time='八月报'
      }
      if(this.report_time=='009'){
        this.report_time='九月报'
      }
      if(this.report_time=='010'){
        this.report_time='十月报'
      }
      if(this.report_time=='011'){
        this.report_time='十一月报'
      }
      if(this.report_time=='012'){
        this.report_time='十二月报'
      }
      var option = {
        title: {
          text: this.yearList,
          left: "49%",
          top: "87%",
          textAlign: "center",
          textStyle: {
            fontSize: "18px",
            // color: "#3A85FD",
            fontWeight: 500,
          },
        },
        color: ["#529EE2", "#BCCCFF", "#97C9FB", "#F88E36"],
        // tooltip: {
        //   trigger: "item",
        //   formatter: numFormat("{c}"),
        // },
        // legend: {
        //   data: ["Show", "Click", "Visit", "Inquiry", "Order"],
        // },
        series: [
          {
            name: "Funnel",
            type: "funnel",
            left: "10%",
            top: 45,
            bottom: 60,
            width: "80%",
            // min: 0,
            // max: 100,
            minSize: "20%",
            maxSize: "100%",
            sort: "none",
            // gap: 2,
            label: {
              show: true,
              position: "inside",
              formatter :function(param){
                  if(parseInt(param.name)<0){
                   return  "{nameStyle|" +
                        param.name +
                        "}"
                  }
                },
                rich: {
                  nameStyle: {
                    color: "red",
                  },
                }
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            // emphasis: {
            //   label: {
            //     fontSize: 20,
            //   },
            // },
            data: param,
          },
        ],
      };

      this.$echarts.init(this.$refs.Funnel).setOption(option); //可以解决echarts组件只加载一次问题
    },
  },
};
</script>

<style lang="less" scoped >
#Funnel {
  padding-left: 40px;
  display: flex;
  width: 32%;

  height: 320px;
}
</style>