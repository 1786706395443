<template>
    <div>
        <!-- <div>{{a}}</div> -->
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'publicOutsider',
    dec: '外部模块',
    porps: ['a'],
    created(){
        // console.log(this);
        
    }
}
</script>